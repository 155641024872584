<template>
	<div>
		<NavHeader></NavHeader>
		<div class="login_page flex_column">
			<div class="content" :class="language == 'en' ? 'en_style3': ''">
				<div class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"
							:class="language == 'en' ? 'en_style2': ''">{{$t('login.account')}}</span>:
					</div>
					<el-input class="flex_1"
						:placeholder="$t('common.please')+$t('login.account1')" v-model="formData.loginName" size="mini"
						clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"
							:class="language == 'en' ? 'en_style2': ''">{{$t('login.password')}}</span>:
					</div>
					<el-input type="password"   show-password class="flex_1"
						:placeholder="$t('common.please')+$t('login.password')" v-model="formData.loginPassword"
						size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('login.code')}}</span>:
					</div>
					<el-input class="flex_1"  :placeholder="$t('login.code')"
						maxlength="4" v-model="formData.captcha" size="mini" clearable></el-input>
					<img class="captcha" :src="captchaImg" @click="captcha" />
				</div>
				<div class="mid flex_row">
					<div class="text"></div>
					<div class="flex_1 cursor flex_row"
						style="color: #a4a498;justify-content: space-between; align-items: flex-start;">
						<div @click="$router.push('/retrievePassword')">{{$t('login.forgot')}}</div>
						<div @click="$router.push('/registered')">{{$t('login.noReg')}}</div>
					</div>
				</div>
				<div class="mid zui-flex-row zui-justify-between">
					<div class="text"></div>
					<el-checkbox-group v-model="formData.remember_me" fill="#a4a498" text-color="#f00">
						<el-checkbox v-model="formData.remember_me" size="mini" style="color: #a4a498; margin: 0">
							{{$t('login.remember')}}</el-checkbox>
					</el-checkbox-group>
				</div>
				<div class="mid flex_row" style="height: 50px">
					<div class="flex_1 flex_row">
						<el-button size="mini" @click="doLogin" type="warning" class="login">{{$t('login.login')}}
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getCaptcha,
		login
	} from '../api/api';
	import {
		mapState
	} from 'vuex';
	import NavHeader from '../components/NavHeader.vue';
	export default {
		name: 'index',
		data() {
			return {
				captchaImg: '',
				formData: {
					loginName: '',
					loginPassword: '',
					captcha: '',
					checkKey: '',
					remember_me: false
				}
			};
		},
		components: {
			NavHeader
		},

		computed: {
			...mapState(['userInfo', 'isLogin', 'language'])
		},
		created: function() {
			this.captcha();
			if (this.isLogin) {
				this.$router.push({
					name: '/index'
				});
			}
		
			var _that = this;
		
			document.onkeydown = function() { //实现回车登录
				var path= _that.$router.history.current.path;
				var key = window.event.keyCode;
				if (key == 13&& path=='/login') {
					_that.doLogin();
						
				}
			}
		


		},
		methods: {
			captcha: function() {
				this.formData.checkKey = Date.parse(new Date());
				let formData = {
					_t: this.formData.checkKey
					// unUseToken: true ,
				};
				getCaptcha(this.formData.checkKey, formData).then(res => {
					// console.log(res);
					if (res.code == 0) {
						this.captchaImg = res.result;
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			doLogin() {
				// console.log(this.formData);
				//定义表单规则
				var rule = [{
						name: "loginName",
						checkType: "notnull",
						checkRule: "",
						errorMsg: this.$t('login.username')
					},
					{
						name: "loginPassword",
						checkType: "notnull",
						checkRule: "",
						errorMsg: this.$t('login.password')
					},
				];
				if (!this.$zuiChecker.check(this.formData, rule)) {
					this.$message({
						message: this.$zuiChecker.error,
						showClose: true,
						type: 'error',
						offset: 20,
						duration: 2000,
						customClass: 'hahaha'
					});
					return;
				}
				login(this.formData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						//记录密码
						// if (this.formData.remember_me == true) {
						this.$storage.setItem('userInfo', res.result.userInfo);
						this.$storage.setItem('token', res.result.token)
						// }
						// this.captchaImg = res.result;
						this.$store.dispatch('saveUserInfo', res.result.userInfo);
						this.$store.dispatch('saveUserToken', res.result.token);
						this.$message({
							message: this.$t('login.successMesg'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/index'
								});
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							type: 'error',
							offset: 20,
							customClass: 'hahaha',
							duration: 2000,
						});
						this.captcha();
						// console.log(res.message);
					}
				});
			},

			toReg() {
				this.$router.push('/registered');
			}
		},
		mounted() {}
	};
</script>
<style lang="scss">
	.hahaha {
		z-index: 3999 !important;
	}

	.justify {
		display: inline-block;
		width: 60px;
		text-align-last: justify;
		text-transform: uppercase;
	}

	body {
		height: auto;
	}

	.en_style1 {
		width: auto !important;
	}

	.en_style2 {
		width: 180px;
		text-align-last: right !important;
		vertical-align: middle;
	}

	.en_style3 {
		width: 420px !important;
	}

	.login_page {
		margin-top: 60px;
		width: 100%;
		height: calc(100vh - 60px);
		background: url('../assets/image/02.jpg') no-repeat center;
		background-size: cover;
	
	.content {
			width: 282px;
			margin-bottom: 100px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;

			.mid {
				margin: 10px 0;

				.text {
					font-size: 14px;
					line-height: 30px;
					color: #000000;
					width: 80px;
					text-align: right;
					margin-right: 10px;
				}
			}
		}

		.login {
			margin-top: 20px;
			background: #DBB96E;
			border-color: #DBB96E;
			width: 40%;
		}
	}

	.el-checkbox__label {
		padding-left: 3px;
	}

	.captcha {
		width: 90px;
		height: 25px;
		margin-left: 5px;
		cursor: pointer;
	}

	.el-checkbox__inner {
		border-radius: 50px;
		// border-color: rgb(164, 164, 152);
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: rgb(164, 164, 152);
	}

	.el-checkbox__input.is-checked .el-checkbox__inner {
		border-color: #edb232;
		background-color: #edb232;
	}

	.el-input__inner:hover {
		border-color: #edb232;
		// background-color: #edb232;
	}

	// 手机适配
	@media screen and (max-width: 960px) {
		.content {
			width: 98% !important;
		}

		.en_style2 {
			width: 120px !important;
		}

	}
</style>
